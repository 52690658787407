import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import sessionTimelineService from '../../services/sessionTimelineService';
import windowService from '../../services/windowService';
import { PrimaryButton } from '../../components/Buttons';
import { handleError } from '../../utils/apiUtils';
import useSessionTimeline from '../../hooks/useSessionTimeline';

export const getPresentationMediaArchiveName = (
  curriculumName,
  curriculumAgeCategory,
  sessionScheduledDate,
  timelineName
) =>
  curriculumName && sessionScheduledDate && timelineName
    ? `${curriculumName}_${curriculumAgeCategory}_${sessionScheduledDate}_${timelineName}_Media`
    : null;

const DownloadPresentationMediaButton = ({ originalTimelineId, timelineId, archiveName, ButtonComponent }) => {
  const { timeline } = useSessionTimeline();
  const timelineSegments = timeline?.segments;
  const archiveId = timelineSegments?.archiveId;
  const [isProcessing, setIsProcessing] = useState(false);
  const [hasMedia, setHasMedia] = useState();

  useEffect(() => {
    if (timelineSegments?.length > 0) {
      const segments = Object.values(timelineSegments);
      const sections = segments.flatMap(segment =>
        segment.sections.map(section => ({
          ...section,
          enabled: segment.enabled,
        }))
      );
      const mediaSections = sections.filter(section => section?.media);
      const isMediaEnabled = mediaSections.some(section => section?.enabled);

      setHasMedia(isMediaEnabled);
    }
  }, [timelineSegments]);

  const download = () => {
    setIsProcessing(true);

    sessionTimelineService
      .getPublishedAdminSessionTimeline(originalTimelineId, timelineId)
      .then(timeline => {
        if (!timeline.archiveId) {
          throw new Error('No archive ID found');
        }
        return sessionTimelineService.getArchiveUrl(timeline.archiveId, archiveName);
      })
      .then(windowService.openUrl)
      .catch(handleError)
      .finally(() => setIsProcessing(false));
  };

  return (
    <ButtonComponent
      disabled={(!hasMedia && (!archiveId || !archiveName)) || isProcessing}
      operating={isProcessing}
      onClick={download}
      data-tracking-id="download-presentation-media"
    >
      {isProcessing ? 'Generating Download' : 'Download Presentation Media'}
    </ButtonComponent>
  );
};

DownloadPresentationMediaButton.defaultProps = {
  ButtonComponent: PrimaryButton,
};

DownloadPresentationMediaButton.propTypes = {
  originalTimelineId: PropTypes.string,
  timelineId: PropTypes.string,
  archiveId: PropTypes.string,
  archiveName: PropTypes.string,
  timelineSegments: PropTypes.array,
  ButtonComponent: PropTypes.elementType,
};

export default DownloadPresentationMediaButton;
