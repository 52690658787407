import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import curriculumService from '../services/curriculumService';
import LoadingState from '../components/LoadingState';
import { ErrorMessage } from '../components/AlertMessages';
import { FullWidthCard, Container } from '../components/Layout';
import BibleStudyNavigation from './BibleStudyNavigation';
import HelpAndInfoBox from '../components/HelpAndInfoBox';

import { Button, PrimaryButton } from '../components/Buttons';
import { SessionDateHeaderAndDetails, SessionDescription } from '../components/SessionElements';
import SessionTimeline from './timeline/SessionTimeline';
import DownloadPresentationMediaButton, {
  getPresentationMediaArchiveName,
} from './timeline/DownloadPresentationMediaButton';
import windowService from '../services/windowService';
import sessionTimelineService from '../services/sessionTimelineService';
import { handleError } from '../utils/apiUtils';
import { ymdFromDate } from '../utils/dateUtils';
import { SessionTimelineProvider } from '../contexts/SessionTimelineContext';
import useCurriculum from '../hooks/useCurriculum';
import SessionTimelinePrintStyles from './timeline/SessionTimelinePrintStyles';
import useEventBusSubscription from '../hooks/useEventBusSubscription';
const PreviewButton = ({ url }) => (
  <PrimaryButton onClick={() => windowService.openRoute(url, true)}>
    <i className="fas fa-external-link-alt" /> View as Group Leader
  </PrimaryButton>
);

export default function ManageBibleStudySession() {
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId, sessionId, timelineId, originalTimelineId } = useParams();
  const [data, setData] = useState({ isLoading: true });
  const presentationMediaArchiveName = getPresentationMediaArchiveName(
    brand.name,
    ageCategory,
    ymdFromDate(data.session?.scheduledDate),
    data.timeline?.name
  );

  useEventBusSubscription('TcSegmentsOrdered', ({ id }) => {
    setData(prevData => ({ ...prevData, timeline: { ...prevData.timeline, id: id } }));
  });

  const [crumbs, setCrumbs] = useState([
    { name: 'Curriculum', route: '#/bible-studies' },
    {
      name: `${brand.name}: ${ageCategory}`,
      route: `#/manage-bible-study/schedule/${brand.code}/${ageCategory}/${curriculumId}`,
    },
  ]);

  useEffect(() => {
    Promise.all([
      curriculumService.getSessionFromCurriculumSchedule(curriculumId, sessionId),
      sessionTimelineService.getPublishedAdminSessionTimeline(originalTimelineId, timelineId),
    ])
      .then(([session, timeline]) => {
        setCrumbs(prevCrumbs => [...prevCrumbs, { name: 'Leader Guide' }]);
        setData({ session, timeline, isLoading: false, isError: false });
      })
      .catch(error => {
        handleError(error);
        setData({ isLoading: false, isError: true });
      });
  }, []);

  return (
    <>
      <SessionTimelinePrintStyles options={brand.printOptions} />

      <BibleStudyNavigation
        isLoading={data.isLoading}
        crumbs={crumbs}
        curriculumId={curriculumId}
        activeTab="schedule"
      />
      <FullWidthCard>
        {data.isLoading ? (
          <Container>
            <LoadingState />
          </Container>
        ) : data.isError ? (
          <Container>
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          </Container>
        ) : (
          <>
            <Container data-qa-hook="manageTimelineViewContainer">
              <HelpAndInfoBox
                iconClass="fas fa-chalkboard-teacher"
                title="Session Leader Guide"
                description="Here you can customize this session’s leader guide to fit your church’s time by including or excluding activities. You can also add notes to activities to give your leaders additional information."
              />
              <SessionDateHeaderAndDetails session={data.session}>
                <p>{data.session.getAvailableThroughMessage('MMMM d, yyyy')}</p>
              </SessionDateHeaderAndDetails>
              <SessionDescription dangerouslySetInnerHTML={{ __html: data.session.description }}></SessionDescription>
            </Container>
            <SessionTimelineProvider
              timeline={data.timeline}
              curriculum={{ brandCode: brand.code, name: ageCategory }}
              session={data.session}
            >
              <SessionTimeline
                customizable
                title={`Manage ${data.timeline.name}`}
                subtitle="Manage session activities and add notes for your leaders"
                actionButtons={
                  <>
                    <PreviewButton
                      url={`/curriculum/${brand.code}/${ageCategory}/session/${sessionId}/issue/${data.session.issueId}/timeline/${originalTimelineId}/${timelineId}/preview`}
                    />
                    {presentationMediaArchiveName && (
                      <DownloadPresentationMediaButton
                        originalTimelineId={originalTimelineId}
                        timelineId={data.timeline.id}
                        archiveName={presentationMediaArchiveName}
                        ButtonComponent={Button}
                      />
                    )}
                  </>
                }
              />
            </SessionTimelineProvider>
          </>
        )}
      </FullWidthCard>
    </>
  );
}
